import React from "react"
import '../../../../../css/page/Options.css'

const OptionLicense = props => {
    const { show, license, handleUpdate, handleMain, handleInstall } = props

    return (
        <div className={"overOptions licenseOptions " + (show ? " show" : "")}>
            <nav className="options">
                <p className="item" onClick={ () => { handleUpdate(license.id) } }>Modifier les informations</p>
                <p className={"item" + (license.main === 1 ? " locked" : "")} onClick={ (license.main === 0 ? () => { handleMain(license.id) } : () => {}) }>Définir comme licence principale</p>
                <p className="item" onClick={ () => { handleInstall(license.id) } }>Générer un code d'installation</p>
            </nav>
        </div>
    )
}

export default OptionLicense