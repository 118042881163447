import React from "react";
import LoaderCircle from "../../loader/LoaderCircle";

function ConfirmMainLicense (props) {
    const { license, handleClose, handleMain, saving } = props;

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox yesOrNo">
                    <div className="form margin">
                        <p className="title">Définir comme licence principale</p>
                        <p className="text">
                            <strong>ATTENTION</strong> : Cette licence sera considérée comme caisse principale et va donc redémarrer automatiquement. Cela peut avoir une incidence directe sur le fonctionnement du système de caisse.
                        </p>
                        <button className="delete block" onClick={ () => { handleMain(license) } }>
                            { saving ? "En cours..." : "Oui, j'ai compris !" }
                            <LoaderCircle display="loader submitLogin" hide={!saving ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF" />
                        </button>
                        <button className="cancel" onClick={ handleClose }>Annuler</button>
                        <div className="clearing" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ConfirmMainLicense;
